.selected-mention {
    background-color: #3498db;
    color: white;
  }

.mentions-input textarea {
    /* padding: 10px; */
  }

.tour-step-link {
    color: #1d4ed8;
    text-decoration: underline;
    text-decoration-color: #1d4ed8;
}

.tour-step-link:hover {
    color: #3b82f6;
    text-decoration-color: #3b82f6;
}

ul li {
    margin-bottom: 5px;
}

.custom-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999999999 !important;
    pointer-events: all;
}

.custom-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000000000 !important;
}

.custom-modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
    z-index: 100000000001 !important;
    pointer-events: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 200px;
}

.modal-header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.modal-body {
    font-size: 16px;
    margin-bottom: 20px;
    color: #555;
    text-align: center;
}

.modal-footer {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
}

.btn {
    width: 150px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.confirm {
    background-color: #4CAF50;
    color: white;
}

.cancel {
    background-color: #f44336;
    color: white;
}

.btn:hover {
    opacity: 0.8;
}


.custom-modal button {
    pointer-events: auto;
}

.driver-popover,
.driver-step {
    pointer-events: none !important;
}

.driver-container {
    pointer-events: none !important;
}