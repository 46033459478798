/* MarkdownStyles.css */
.markdown-container {
  --base-font: 'Inter', system-ui, -apple-system, sans-serif;
  --code-font: 'Fira Code', Consolas, Monaco, monospace;

  /* Spacing scale */
  --space-xs: 0.5rem;
  --space-sm: 0.75rem;
  --space-md: 1rem;
  --space-lg: 1.5rem;
  --space-xl: 2rem;

  /* Shared theme properties */
  --radius-sm: 4px;
  --radius-md: 8px;
  --transition: 0.2s ease-in-out;
}

/* Light theme */
.light-theme {
  --text-primary: #1a1a1a;
  --text-secondary: #4a4a4a;
  --background-primary: #ffffff;
  --background-secondary: #f8f9fa;
  --accent-primary: #2563eb;
  --accent-secondary: #1d4ed8;
  --border-primary: #e5e7eb;
  --border-secondary: #d1d5db;
  --code-background: #f3f4f6;
  --table-header-bg: #f1f5f9;
  --table-border: #e5e7eb;
  --table-zebra: #f8fafc;
  --blockquote-bg: #f3f4f6;
  --blockquote-border: #2563eb;
  --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.05);
  --image-border: rgba(0, 0, 0, 0.1);
}

/* Dark theme */
.dark-theme {
  --text-primary: #f8fafc;
  --text-secondary: #e2e8f0;
  --background-primary: #0f172a;
  --background-secondary: #1e293b;
  --accent-primary: #60a5fa;
  --accent-secondary: #3b82f6;
  --border-primary: #334155;
  --border-secondary: #475569;
  --code-background: #1e293b;
  --table-header-bg: #1e293b;
  --table-border: #334155;
  --table-zebra: #1e293b;
  --blockquote-bg: #1e293b;
  --blockquote-border: #60a5fa;
  --shadow-sm: 0 1px 3px rgba(255, 255, 255, 0.05);
  --image-border: rgba(255, 255, 255, 0.1);
}

.markdown {
  font-family: var(--base-font);
  line-height: 1.6;
  color: var(--text-primary);
  max-width: 800px;
  margin: 0 auto;
  /*padding: var(--space-lg) 0;*/
}

/* ======== Headings ======== */
.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  font-weight: 600;
  margin: var(--space-lg) 0 var(--space-sm);
}

.markdown h1 {
  font-size: 2rem;
  padding-bottom: var(--space-sm);
  border-bottom: 2px solid var(--accent-primary);
}

.markdown h2 {
  font-size: 1.75rem;
  padding-bottom: var(--space-xs);
  border-bottom: 1px solid var(--border-primary);
}

.markdown h3 {
  font-size: 1.5rem;
}

.markdown h4 {
  font-size: 1.25rem;
}

.markdown h5 {
  font-size: 1.125rem;
}

.markdown h6 {
  font-size: 1rem;
  color: var(--text-secondary);
}

/* ======== Lists ======== */
.markdown ul,
.markdown ol {
  padding-left: var(--space-xl);
  margin: var(--space-md) 0;
}

.markdown li {
  margin: var(--space-xs) 0;
  padding-left: var(--space-sm);
}

/* Unordered list bullets */
.markdown ul {
  list-style-type: none;
}

.markdown ul li {
  position: relative;
  padding-left: 1rem; /* Adjust padding as needed */
}

.markdown ul li::before {
  content: '-';
  position: absolute;
  left: 0;
  color: var(--accent-primary);
  font-weight: 500;
}

/* Ordered list numbering */
.markdown ol {
  counter-reset: top-level;
}

.markdown ol > li {
  counter-reset: nested-level;
  counter-increment: top-level;
}

.markdown ol > li::before {
  content: counter(top-level) ".";
  font-weight: 500;
  color: var(--accent-primary);
  margin-right: 0.5rem; /* Adjust spacing */
}

/* Nested ordered lists */
.markdown ol ol {
  counter-reset: nested-level;
}

.markdown ol ol > li {
  counter-increment: nested-level;
}

.markdown ol ol > li::before {
  content: counter(top-level) "." counter(nested-level);
  color: var(--text-secondary);
  margin-right: 0.5rem; /* Adjust spacing */
}

/* Third-level nesting */
.markdown ol ol ol {
  counter-reset: third-level;
}

.markdown ol ol ol > li {
  counter-increment: third-level;
}

.markdown ol ol ol > li::before {
  content: counter(top-level) "." counter(nested-level) "." counter(third-level);
  color: var(--text-secondary);
  margin-right: 0.5rem; /* Adjust spacing */
}

/* Fourth-level nesting */
.markdown ol ol ol ol {
  counter-reset: fourth-level;
}

.markdown ol ol ol ol > li {
  counter-increment: fourth-level;
}

.markdown ol ol ol ol > li::before {
  content: counter(top-level) "." counter(nested-level) "." counter(third-level) "." counter(fourth-level);
  color: var(--text-secondary);
  margin-right: 0.5rem; /* Adjust spacing */
}

/* ======== Code Blocks ======== */
.code-block {
  position: relative;
  margin: var(--space-md) 0;
  border-radius: var(--radius-md);
  overflow: hidden;
  box-shadow: var(--shadow-sm);
  background: var(--code-background);
}

.code-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-xs) var(--space-sm);
  background-color: var(--background-secondary);
  border-bottom: 1px solid var(--border-primary);
}

.language-tag {
  font-family: var(--code-font);
  font-size: 0.875rem;
  color: var(--text-secondary);
  font-weight: 500;
}

.copy-button {
  background: var(--background-primary);
  border: 1px solid var(--border-primary);
  padding: var(--space-xs) var(--space-sm);
  border-radius: var(--radius-sm);
  display: flex;
  align-items: center;
  gap: var(--space-xs);
  transition: var(--transition);
}

.copy-button:hover {
  background: var(--accent-primary);
  border-color: var(--accent-secondary);
  color: white;
}

.copy-icon {
  width: 18px;
  height: 18px;
}

/* ======== Tables ======== */
.table-container {
  overflow-x: auto;
  margin: var(--space-md) 0;
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--border-primary);
}

.markdown table {
  width: 100%;
  border-collapse: collapse;
  background: var(--background-primary);
  min-width: 600px;
}

.markdown th {
  background: var(--table-header-bg);
  padding: var(--space-sm) var(--space-md);
  font-weight: 600;
  text-align: left;
  border-bottom: 2px solid var(--table-border);
  color: var(--accent-primary);
}

.markdown td {
  padding: var(--space-sm) var(--space-md);
  border-bottom: 1px solid var(--table-border);
  color: var(--text-primary);
}

.markdown tr:nth-child(even) {
  background: var(--table-zebra);
}

.markdown tr:hover {
  background: var(--background-secondary);
}

/* ======== Blockquotes ======== */
.styled-blockquote {
  margin: var(--space-md) 0;
  padding: var(--space-md);
  background: var(--blockquote-bg);
  border-left: 4px solid var(--blockquote-border);
  border-radius: var(--radius-md);
}

.styled-blockquote p {
  color: var(--text-primary);
  font-size: 1.125rem;
  line-height: 1.75;
  margin: 0;
}

/* ======== Horizontal Rules ======== */
.markdown hr {
  border: 0;
  height: 1px;
  background: var(--border-primary);
  margin: var(--space-xl) 0;
  position: relative;
}

.markdown hr::after {
  content: '•••';
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--background-primary);
  padding: 0 var(--space-sm);
  color: var(--text-secondary);
  letter-spacing: 0.5em;
}

/* ======== Responsive Design ======== */
@media (max-width: 768px) {
  .markdown {
    padding: var(--space-md);
    font-size: 0.9375rem;
  }

  .markdown h1 {
    font-size: 1.75rem;
  }

  .markdown ul,
  .markdown ol {
    padding-left: var(--space-lg);
  }

  .table-container {
    border-radius: 0;
  }
}