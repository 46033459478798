/* Popover background and text color */
.driver-popover.driverjs-theme {
    background-color: white;  /* Default background color */
    color: #000;  /* Default text color */
}

/* Title and description text color */
.driver-popover.driverjs-theme .driver-popover-title,
.driver-popover.driverjs-theme .driver-popover-description,
.driver-popover.driverjs-theme .driver-popover-progress-text {
    color: #000;  /* Text color */
}

/* Navigation button styles */
.driver-popover.driverjs-theme button {
    text-align: center;
    background-color: #6453f0;  /* New background color for buttons */
    color: #ffffff;  /* White text color */
    border: 2px solid #6453f0;  /* Match the button border color */
    text-shadow: none;
    font-size: 12px;
    padding: 5px 8px;
    border-radius: 6px;
}

/* Hover state for navigation buttons */
.driver-popover.driverjs-theme button:hover {
    background-color: #4f40c0;  /* Slightly darker background on hover */
    color: #ffffff;
}

/* Navigation buttons container */
.driver-popover.driverjs-theme .driver-popover-navigation-btns {
    gap: 3px;
}

/* Close button styles - no background */
.driver-popover.driverjs-theme .driver-popover-close-btn {
    background-color: transparent;  /* No background color */
    color: #6453f0;  /* Text color for the close button */
    border: none;  /* No border */
    font-size: 18px;
}

/* Close button hover state */
.driver-popover.driverjs-theme .driver-popover-close-btn:hover {
    background-color: transparent;  /* Ensure no background color on hover */
    color: grey;  /* Change text color on hover (adjust as needed) */
}

/* Arrow colors */
.driver-popover.driverjs-theme .driver-popover-arrow-side-left.driver-popover-arrow {
    border-left-color: #fde047;  /* Default arrow color */
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-right.driver-popover-arrow {
    border-right-color: #fde047;  /* Default arrow color */
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-top.driver-popover-arrow {
    border-top-color: #fde047;  /* Default arrow color */
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-bottom.driver-popover-arrow {
    border-bottom-color: #fde047;  /* Default arrow color */
}
